import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import "./Navbar.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// const LeftMenu = () => {
//   const mobileView = window.innerWidth < 960;
//   const history = useHistory();
//   const pathName = history.location.pathname.includes("lennyAssistance");

//   return (
//     <Menu
//       mode={mobileView ? "vertical" : "horizontal"}
//       style={{
//         background: pathName ? "transparent" : "#FFFFFF",
//         placeContent: "center",
//         border: "none",
//         // paddingTop: "15px"
//       }}
//     >
//       <Menu.Item key="chatbot">
//         <a href="/lennyAssistance">Chatbot</a>
//       </Menu.Item>
//       <Menu.Item key="winners">
//         <a href="/partners">Partners</a>
//       </Menu.Item>
//       <Menu.Item key="pricing">
//         <a href="/pricing">Pricing</a>
//       </Menu.Item>
//       <Menu.Item key="aboutUs">
//         <a href="/about-us">About Us</a>
//       </Menu.Item>
//       <Menu.Item key="scholarships">
//         <a href="http://business.lenny.ai">Business</a>
//       </Menu.Item>
//       <Menu.Item key="about">
//         <a href="/faq">FAQ</a>
//       </Menu.Item>
//       <Menu.Item key="connect">
//         <a href="/contact">Contact</a>
//       </Menu.Item>
//     </Menu>
//   );
// };
const LeftMenu = () => {
  const mobileView = window.innerWidth < 960;
  const location = useLocation();
  const pathName = location.pathname.includes("lennyAssistance");

  const menuItems = [
    { key: "chatbot", path: "/lennyAssistance", label: "Chatbot" },
    { key: "winners", path: "/partners", label: "Partners" },
    { key: "pricing", path: "/pricing", label: "Pricing" },
    { key: "aboutUs", path: "/about-us", label: "About Us" },
    {
      key: "scholarships",
      path: "http://business.lenny.ai",
      label: "Business",
    },
    { key: "about", path: "/faq", label: "FAQ" },
    { key: "connect", path: "/contact", label: "Contact" },
  ];

  return (
    <Menu
      mode={mobileView ? "vertical" : "horizontal"}
      style={{
        background: pathName ? "transparent" : "#FFFFFF",
        placeContent: "center",
        border: "none",
      }}
    >
      {menuItems.map((item) => (
        <Menu.Item
          key={item.key}
          className={location.pathname === item.path ? "active-menu-item" : ""}
        >
          <a href={item.path}>{item.label}</a>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default LeftMenu;
