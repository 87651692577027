import { Button, Col, Form, Row, Space, Spin, notification, Modal } from "antd";
import useAxios from "axios-hooks";
import Flex from "components/shared-components/Flex";
import { useEffect, useState } from "react";
import { ScholarshipService_ } from "services/scholarship.service";
import ApplicationStatus from "./ApplicationStatus";
import Requirement from "./Requirement";
import ScholarshipInfo from "./ScholarshipInfo";
import ThankYouModal from "./ThankYouModal";
import { createPayload, getInitialValues } from "./utils";
import HtmlPopup from "../components/HtmlPopUp";
import Scrollbars from "react-custom-scrollbars-2";
import RequirementAss from "./RequirementAss";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import MobileReqAss from "components/layout-components/MobileReqAss";

const ScholarshipDetail = ({ history, props }) => {
  // console.log("🚀 ~ ScholarshipDetail ~ props:", props);

  const {
    showChat,
    setShowChat,
    reqAssMaxView,
    setReqMaxView,
    mobReqAssMaxView,
    setMobReqMaxView,
  } = useAssitanceChatBotContext();

  const id = history?.location?.search?.slice(1);
  const [{ data = {}, loading }] = useAxios(`/scholarships/${id}`);
  const [{ data: existingData, loading: loadingExistingData }] = useAxios(
    `/scholarships/${id}/applicants/get-applicant-details`
  );
  const [completedSteps, setCompletedSteps] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 950;
  const mView = window.innerWidth < 500;
  const [essayReq, setEssayReq] = useState([]);

  const [startChat, setStartChat] = useState(false);
  const [assMessages, setAssMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (data?.requirements) {
      let isEssayReq = data?.requirements?.filter((elm) => {
        return elm.field.dataType === "text" || elm.field.dataType === "editor";
      });
      // console.log("🚀 ~ isEssayReq ~ isEssayReq:", isEssayReq);
      setEssayReq(isEssayReq);
    }
  }, [data]);

  useEffect(() => {
    if (props?.setSideBars) {
      props.setSideBars(false);
    }
  }, []);

  // console.log("🚀 ~ ScholarshipDetail ~ isModalVisible:", isModalVisible);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const onFormFieldsChange = (_, values) => {
    let completed = 1;

    data?.requirements?.forEach((item, idx) => {
      if (!!values[item?.field?.name]) {
        completed = idx + 2;
      }
    });

    setCompletedSteps(completed);
  };

  const onSubmitForm = (values, e) => {
    const payload = createPayload(values, data);
    console.log("🚀 ~ onSubmitForm ~ payload:", payload);
    ScholarshipService_.submitIndividualScholarship(payload)
      .then((res) => {
        userInfo.scholarshipCount = res?.data?.scholarshipCount;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        setModalOpen(true);
      })
      .catch((err) => {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  const onSubmitDraft = () => {
    const values = form.getFieldsValue();

    let payload = createPayload(values, data);
    payload = { ...payload, status: "Draft" };
    ScholarshipService_.submitIndividualScholarship(payload)
      .then((res) => {
        notification.open({
          message: "Saved draft",
          type: "success",
        });
      })
      .catch((err) => {
        notification.open({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  const onCloseModal = () => {
    setModalOpen(false);
    // history.goBack();
    history.push("/auth/dashboard");
  };

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "720px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );
  }

  const AssitanceChatMiniView = () => {
    const mobileView = window.innerWidth < 915;

    const [essayBtnStat, setEssayBtnStat] = useState(false);

    const btnStyle = {
      fontWeight: "400",
      color: "black",
      border: "2px solid rgb(141, 150, 255)",

      // padding: "10px",
      borderRadius: "12px",
      cursor: "pointer",
      fontSize: "14px",
      padding: "6px 15px",
      textAlign: "center",
    };

    const writeEssay = () => {
      setStartChat(true);
    };

    const applyScholarship = () => {
      setStartChat(true);
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(240, 241, 255)",
          borderRadius: "10px",
          marginLeft: "10px",
          padding: "16px",
          // height: "100%",
        }}
      >
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "black",
            margin: "0",
          }}
        >
          Welcome to Lenny.AI 👋
        </h3>
        <div
          style={{
            width: "240px",
            margin: "auto",
            display: essayBtnStat ? "none" : "flex",
          }}
        >
          <img
            src="/new/chatrobo1.png"
            style={{
              width: "100%",
            }}
            alt=""
          />
        </div>
        <div
          style={{
            display: essayBtnStat ? "none" : "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            // flexWrap: "wrap",
          }}
        >
          <p
            style={{
              ...btnStyle,
              margin: "4px 5px 0 0",
            }}
            onClick={writeEssay}
          >
            Write Essay
          </p>
          <p
            style={{
              ...btnStyle,
              // marginRight: "10px",
              margin: "0",
              marginTop: "4px",
            }}
            onClick={applyScholarship}
          >
            Apply Scholarship
          </p>
        </div>
      </div>
    );
  };

  const AssistanceChatView = () => {
    const mobileView = window.innerWidth < 915;

    const [essayBtnStat, setEssayBtnStat] = useState(false);

    const btnStyle = {
      fontWeight: "400",
      color: "black",
      border: "2px solid rgb(141, 150, 255)",

      // padding: "10px",
      borderRadius: "12px",
      cursor: "pointer",
      fontSize: "14px",
      padding: "6px 15px",
      textAlign: "center",
    };

    // const writeEssay = () => {
    //   setChatTypeFn("recommedation");
    //   // setChatType("writeEssay");
    //   setStartChat(true);
    //   setHideIcons(true);
    //   setEnableChat(true);
    // };

    // const applyScholarship = () => {
    //   // setChatType("applyScholarship");
    //   setChatTypeFn("applyScholarship");

    //   setStartChat(true);
    //   setHideIcons(true);
    //   setEnableChat(true);
    // };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(240, 241, 255)",
          borderRadius: "10px",
          marginLeft: "10px",
          padding: "16px",
          // height: "100%",
        }}
      >
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "black",
            margin: "0",
          }}
        >
          Welcome to Lenny.AI 👋
        </h3>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            marginBottom: "10px",
          }}
        >
          <button
            style={{
              background: "transparent",
              borderColor: "#8D96FF",
              cursor: "pointer",
              width: "auto",
              borderRadius: "12px",
            }}
          >
            <span style={{ fontWeight: "600" }}>Edit</span>
          </button>
          <button
            style={{
              background: "transparent",
              borderColor: "#8D96FF",
              cursor: "pointer",
              width: "auto",
              borderRadius: "12px",
            }}
          >
            <span style={{ fontWeight: "600" }}>Redraft</span>
          </button>
        </div>
        <div
          style={{
            backgroundColor: "white",
            padding: "8px",
            width: "100%",
          }}
        >
          <Scrollbars
            style={{
              height: "260px",
              width: "100%",
              // padding: "20px"
            }}
          >
            <p
              style={{
                fontWeight: "400",
                color: "black",
                fontSize: "14px",
                // textAlign: 'justify'
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
              magni iste vitae totam laudantium ratione voluptatum dignissimos
              accusamus est tempore, dicta ullam aliquid et optio quia fugiat
              maiores consectetur. Quis maiores commodi illo tempora quae porro
              consectetur quos laudantium alias enim expedita fuga asperiores
              similique esse dolores dolore aliquam vero, distinctio, eum, neque
              molestias nesciunt voluptatem quaerat. Voluptatibus, ratione?
            </p>
          </Scrollbars>
        </div>
        <button
          style={{
            backgroundColor: "#8D96FF",
            color: "white",
            fontWeight: "500",
            letterSpacing: "0.5px",
            border: "none",
            marginTop: "10px",
            cursor: "pointer",
          }}
        >
          Save Essay
        </button>
      </div>
    );
  };

  const ScholarshipReq = () => {
    return (
      <>
        <ScholarshipInfo scholarship={data} />
        <div>
          <Form
            name="scholarship-details-form"
            onFinish={onSubmitForm}
            onFinishFailed={() => {
              notification.open({
                message: "Please fill all required fields",
                type: "error",
              });
            }}
            onValuesChange={onFormFieldsChange}
            form={form}
            initialValues={getInitialValues(existingData?.applicantFields)}
            style={{
              width: "100%",
            }}
          >
            {data?.requirements && data?.requirements.length > 0 ? (
              <Space
                direction="vertical"
                size="large"
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "600",
                    fontSize: "24px",
                    color: "black",
                  }}
                >
                  Requirements
                </p>
                {data.requirements.map((item) => (
                  <Requirement
                    key={item.id}
                    details={item}
                    formRef={form}
                    scholarshipId={data.id}
                  />
                ))}
              </Space>
            ) : null}

            <Space
              direction="vertical"
              style={{
                marginBottom: !mView && "80px",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                Terms and Conditions
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: mView ? "column" : "row",
                  justifyContent: "flex-start",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <HtmlPopup
                  buttonLabel="Terms of use"
                  htmlContent={data?.terms}
                />
                <HtmlPopup
                  buttonLabel="Privacy Policy"
                  htmlContent={data?.privacy}
                />
                <HtmlPopup
                  buttonLabel="Affidavit"
                  htmlContent={data?.affidavit}
                />
              </div>
            </Space>

            <Form.Item
              // className=" fixed-bottom"
              style={{
                padding: "15px 20px",
                width: mobileView ? "" : "max-content",
                background: "#fff",
                margin: 0,
                borderTop: "1px solid rgba(29, 37, 45, 0.1)",
                position: mView ? "static" : "fixed",
                bottom: 0, // Position at the bottom
                zIndex: 1000, // Ensure it appears above other elements
                right: mobileView ? 0 : "auto", // Adjust for mobile view if necessary
                left: mobileView ? 0 : "auto", // Adjust for mobile view if necessary
              }}
            >
              {existingData?.status !== "Received" && (
                <Flex
                  flexDirection={window.innerWidth < 500 ? "column" : "row"}
                >
                  {data?.requirements && data?.requirements.length > 0 ? (
                    <Button
                      htmlType="button"
                      name="submit-draft"
                      style={{
                        marginRight: "30px",
                        width: mView ? "100%" : "max-content",

                        // float: "right",
                        borderColor: "transparent",
                        color: "black",
                        fontWeight: "500",
                        borderRadius: "5px",
                        borderColor: "rgb(141, 150, 255)",
                        height: "40px",
                        padding: "0 50px",
                      }}
                      onClick={onSubmitDraft}
                    >
                      Save draft
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: mView ? "100%" : "max-content",
                      // float: "right",
                      borderRadius: "5px",
                      backgroundColor: "rgb(141, 150, 255)",
                      border: "none",
                      height: "40px",
                      padding: "0 50px",
                      marginTop: window.innerWidth < 500 && "8px",
                    }}
                  >
                    Submit application
                  </Button>
                </Flex>
              )}
            </Form.Item>
          </Form>

          <ThankYouModal open={modalOpen} handleOnCloseModal={onCloseModal} />
        </div>
      </>
    );
  };

  return (
    <>
      <Row style={{ minHeight: "calc(100vh - 90px)", overflow: "hidden" }}>
        <Col
          style={{
            // width: mobileView ? "100%" : "70%",
            padding: mobileView ? "0 16px" : "0 24px",
            background: "#ffffff",
            position: "relative",
            boxSizing: "border-box",
          }}
          // span={18}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={18}
        >
          <a
            onClick={() => {
              props.setSideBars(false);
              history.goBack();
            }}
            style={{ color: "#1D252D", fontSize: "20px", fontWeight: "500" }}
          >
            <img
              src="/applicantImg/back.png"
              style={{ height: "18px", marginRight: "4px" }}
            />
            Back to Scholarships
          </a>
          {/* {mobileView && (
            <ApplicationStatus
              requirements={data?.requirements}
              completedSteps={completedSteps}
            />
          )} */}
          {mobileView ? (
            <>
              <ScholarshipReq />
            </>
          ) : (
            <Scrollbars
              style={{
                height: "calc(100vh - 100px)",
                padding: "0 20px",
                overflowY: "hidden",
              }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              // autoHeight
              // autoHeightMax="calc(100vh - 110px)"
            >
              <ScholarshipReq />
            </Scrollbars>
          )}
        </Col>
        {!mobileView && (
          <Col
            style={{
              // width: "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "calc(100vh - 110px)",
              overflow: "hidden",
              position: reqAssMaxView ? "absolute" : "static",
              width: reqAssMaxView ? "-webkit-fill-available" : "25%",
              margin: reqAssMaxView ? "auto" : "0",

              bottom: 0,
              zIndex: 1000,
            }}
            // offset={3}
            span={reqAssMaxView ? 24 : 6}
          >
            <div
              style={{
                flex: "1 1 auto",
                overflowY: "auto",
                marginBottom: "10px",
                display: reqAssMaxView ? "none" : "block",
              }}
            >
              <ApplicationStatus
                requirements={data?.requirements}
                completedSteps={completedSteps}
                applicationstatus={existingData?.status}
              />
            </div>
            <div
              style={{
                flex: "0 0 auto",
              }}
            >
              {/* {true && essayReq.length > 0 && ( */}
              {showChat && essayReq.length > 0 && (
                <RequirementAss
                  essayReq={essayReq}
                  assMessages={assMessages}
                  setAssMessages={setAssMessages}
                />
              )}
              {/* {startChat ? <AssistanceChatView /> : <AssitanceChatMiniView />} */}
            </div>
          </Col>
        )}
      </Row>
      <div
        style={{
          position: "sticky",
          bottom: "145px",
          cursor: "pointer",
          right: "20px",
          float: "right",
          display: tabView && essayReq.length > 0 ? "block" : "none",
        }}
        onClick={() => {
          // history.push("/auth/dashboard/assistanceChat");
          // setIsModalVisible(true)
          setShowChat(true);
        }}
      >
        <img
          src="/applicantImg/robo2.png"
          style={{
            width: "75px",
          }}
          alt=""
        />
      </div>
      {tabView && showChat && essayReq.length > 0 && (
        <div
          style={{
            position: mobReqAssMaxView ? "absolute" : "static",
            width: mobReqAssMaxView ? "97%" : "25%",
            margin: mobReqAssMaxView ? "auto" : "0",
          }}
        >
          <RequirementAss
            isMobileView={true}
            essayReq={essayReq}
            assMessages={assMessages}
            setAssMessages={setAssMessages}
          />
        </div>
      )}
    </>
  );
};

export default ScholarshipDetail;
