import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React from "react";
import TextEditor from "./TextEditor";
import { UploadOutlined } from "@ant-design/icons";

const Requirement = ({ details, formRef, scholarshipId }) => {
  // console.log("🚀 ~ Requirement ~ details:", details);
  const { field, title, description, optional } = details;

  const renderField = ({ key, name, dataType, optional }) => {
    // console.log(optional);
    switch (dataType) {
      case "file":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please upload!",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              return val?.file?.response?.data?.publicUrl;
            }}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Upload
              action={API_UPLOAD_URL}
              accept=".doc,.docx,.pdf"
              maxCount={1}
            >
              <Button
                type="primary"
                style={{
                  // backgroundColor: "rgb(141, 150, 255)",
                  // border: "none",
                  border: "none",
                  background: "rgb(141, 150, 255)",
                  padding: "12px 20px", // Adjusted padding to be more balanced
                  borderRadius: "8px",
                  display: "flex", // Added display flex
                  alignItems: "center", // Centered items vertically
                  height: "40px",
                }}
              >
                <UploadOutlined
                  style={{
                    color: "white",
                  }}
                />
                Upload {name}
              </Button>
            </Upload>
          </Form.Item>
        );

      case "link":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
            style={{
              width: "100%",
            }}
          >
            <Input
              placeholder={name}
              style={{
                padding: "8px",
                width: "100%",
                borderRadius: "8px",
              }}
            />
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                message: "Please provide!",
              },
            ]}
            name={key}
            valuePropName="checked"
          >
            <Checkbox>{title}</Checkbox>
          </Form.Item>
        );

      case "text":
      case "editor":
        return (
          <Form.Item
            rules={[
              {
                required: optional,
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject("Please add essay!");
                  }
                  return Promise.resolve();
                },
                validateTrigger: "onsubmit",
              },
            ]}
            name={key}
            getValueFromEvent={(val) => {
              if (val?.essay)
                return {
                  text: val?.essay,
                };
              return {
                url: val?.file?.response?.data,
              };
            }}
            style={{
              width: "100%",
            }}
          >
            {/* <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "black",
              }}
            >
              Write a 800-1000 words {title} on following topics.*
            </p> */}

            <TextEditor scholarshipId={scholarshipId} details={details} />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  function capitalizeFirstLetter(word) {
    if (!word) return ""; // Return empty string if input is empty or null
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <>
      {/* <Col */}
      {/* // span={12} */}
      {/* > */}
      <h3 style={{ fontWeight: "600" }}>
        {capitalizeFirstLetter(title)}{" "}
        {!optional ? "(optional)" : <span style={{ color: "red" }}>*</span>}
      </h3>
      <Typography.Paragraph>{description}</Typography.Paragraph>
      {renderField({
        key: details?.field?.key,
        name: details?.field?.name,
        dataType: details?.field?.dataType,
        optional,
        title,
      })}
      {/* </Col> */}
    </>
  );
};

export default Requirement;
