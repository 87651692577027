import { Button, Drawer, Dropdown, Menu, Space, Steps } from "antd";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import "./Navbar.css";
import { RightMenu } from "./RightMenu.js";
import deleteCookie from "utils/deletecookie";
import mixpanel from "../../../../mixpanel/mixpanel";

const Navbar = ({ props }) => {
  const { Step } = Steps;
  const [visible, setVisible] = useState(false);
  const mobileView = window.innerWidth < 961;
  const history = useHistory();
  const userInfoString = localStorage.getItem("userInfo");
  const [viaDrawer, setViaDrawwer] = useState(false);
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }
  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;

  const stepper = ["/signup", "/auth/userdetails"].includes(props.pathname);

  const login = ["/auth/apply", "/auth/profile", "/auth/dashboard"].includes(
    props.pathname
  );

  const logoStyle = {
    width:
      window.innerWidth < 350
        ? " 90px"
        : window.innerWidth < 400
        ? "120px"
        : window.innerWidth < 600
        ? "165px"
        : "235px",
  };

  const showDrawer = () => {
    setViaDrawwer(true);
    setVisible(true);
  };

  const onClose = () => {
    setViaDrawwer(false);
    setVisible(false);
  };

  //const background = login ? "#ffffff" : "#E6F3F9";
  const background = login ? "#ffffff" : "#ffffff";
  const stepperView = ({ current }) => {
    return (
      <div
        onClick={() => {
          if (checkToken) {
            history.push("/auth/dashboard");
          } else {
            history.push("/");
          }
        }}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginTop: mobileView ? "14px" : "",
          marginLeft: "12px",
          // marginBottom: "12px"
        }}
      >
        <img
          src="/new/oldLogo-removebg-1.png"
          alt="Lenny.AI"
          style={logoStyle}
        />
      </div>
    );
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isActiveSubscription");
    localStorage.removeItem("profileData");
    localStorage.removeItem("zipCodeId");
    localStorage.removeItem("countryObj");
    localStorage.removeItem("formData");
    localStorage.removeItem("dates");
    localStorage.removeItem("linkId");
    localStorage.removeItem("sourceId");
    deleteCookie("trakdesk_cid");
    mixpanel.track("Logout Button Clicked");
    history.push("/");
    window.location.reload();
  };

  const gotoDashboard = () => {
    history.push("/auth/dashboard");
    window.location.reload();
  };

  const profileMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push("/auth/profile");
        }}
      >
        My Profile
      </Menu.Item>
      <Menu.Item onClick={logout}>Logout</Menu.Item>
    </Menu>
  );

  const loginNavView = () => {
    return (
      <div
        style={{ marginTop: mobileView ? "20px" : "15px", textAlign: "right" }}
      >
        {false && (
          <Button
            type="primary"
            onClick={gotoDashboard}
            style={{
              background: "#F1F6FE",
              borderRadius: "6px",
              borderColor: "#F1F6FE",
              height: "32px",
              paddingTop: "5px",
              width: "150px",
              margin: "0 5%",
            }}
          >
            <span style={{ color: "#1778F2" }}>Go to Dashboard </span>
          </Button>
        )}
        <span
          style={{
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            src="/applicantImg/notification.png"
            style={{ height: "25px" }}
          />
        </span>

        <span
          style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "17px",
            letterSpacing: "0em",
          }}
        >
          {!mobileView && fullName}
        </span>

        <span
          style={{
            width: "fit-content",
            borderRadius: "15px",
            marginLeft: "2px",
            marginRight: "15px",
            textAlign: "right",
          }}
        >
          <Dropdown overlay={profileMenu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div style={{ borderRadius: "15px" }}>
                  <Avatar round={true} size="35" name={fullName} />
                </div>
              </Space>
            </a>
          </Dropdown>
        </span>
      </div>
    );
  };
  const menu = () => {
    if (login) return loginNavView();
    return (
      <div
        className="menuCon"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Ensures space is distributed evenly
          // padding: "0 1rem", // Adds some padding to the sides
          height: "inherit",
        }}
      >
        {/* Bars button */}
        {mobileView && (
          <Button
            className="barsMenu"
            style={{
              width: "auto",
              background: "#FFFCF2",
              padding: "0 10px",
              margin: "0",
              border: "none",
            }}
            type="primary"
            onClick={showDrawer}
          >
            <span className="barsBtn"></span>
          </Button>
        )}

        {/* Logo */}
        <div
          onClick={() => {
            if (checkToken) {
              history.push("/auth/dashboard");
            } else {
              history.push("/");
            }
          }}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            src="/new/oldLogo-removebg-1.png"
            alt="Lenny.AI"
            style={logoStyle}
          />
        </div>
        {/* Left menu (only for larger screens) */}
        {!mobileView && (
          <div
            className="leftMenu"
            style={{
              flex: "1 1 60%",
              marginLeft: "1rem",
            }}
          >
            <LeftMenu />
          </div>
        )}

        {/* Right menu */}
        <div
          className="rightMenu"
          style={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
        >
          <RightMenu viaDrawer={viaDrawer} />
        </div>

        {/* Drawer for mobile view */}
        <Drawer
          title="Lenny"
          placement="left"
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu />
          <RightMenu viaDrawer={viaDrawer} />
        </Drawer>
      </div>
    );
  };

  // const menu = () => {
  //   if (login) return loginNavView();
  //   return (
  //     <div className="menuCon" style={{
  //       // paddingTop: "15px",
  //       // height: "25px"
  //       width: "100%",
  //       display: "flex",
  //     }}>

  //       {/* bars btn */}
  //       {mobileView && (
  //         <Button
  //           className="barsMenu"
  //           style={{
  //             width: "auto",
  //             background: "#FFFCF2",
  //             padding: "3% 3% 12%",
  //             // marginRight: "3%",
  //             border: "none",
  //             float: "left"
  //           }}
  //           type="primary"
  //           onClick={showDrawer}
  //         >
  //           <span className="barsBtn"></span>
  //         </Button>
  //       )}
  //       {/* logo */}
  //       <div
  //         // className="logo"
  //         onClick={() => {
  //           if (checkToken) {
  //             history.push("/auth/dashboard");
  //           } else {
  //             history.push("/");
  //           }
  //         }}
  //         style={{
  //           // height: '70px',
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '0px 1rem',
  //           backgroundColor: 'transparent',
  //           transition: 'all 0.2s ease 0s'
  //         }}
  //       >
  //         <img
  //           src="/new/oldLogo-removebg-1.png"
  //           // src="/new/oldLogo.webp"
  //           alt="Lenny.AI"
  //           style={{
  //             width: window.innerWidth < 600 ? "175px" : "235px",
  //             cursor: "pointer",
  //             padding: "0"
  //           }}
  //         />
  //       </div>

  //       <div className="rightMenu">
  //         <RightMenu />
  //       </div>
  //       <div className=" ">
  //         {!mobileView && <LeftMenu />}
  //       </div>

  //       <Drawer
  //         style={{}}
  //         title="Lenny"
  //         placement="left"
  //         // closable={true}
  //         onClose={onClose}
  //         visible={visible}
  //       >
  //         <LeftMenu />
  //         <RightMenu />
  //       </Drawer>
  //     </div>
  //   );
  // };

  const viewSelector = [
    "/",
    "/home",
    "/b2b",
    "/auth/",
    "/auth/login",
    "/auth/register",
    "/auth/register1",
    "/signup",
    "/auth/register3",
    "/auth/apply",
    "/privacy",
    "/terms",
    "/faq",
    "/contact",
    "/partners",
    "/pricing",
    "/about-us",
    //"/chatbot"
  ].includes(history.location.pathname);

  const checkToken = localStorage.getItem("token");

  return !viewSelector ? (
    <div />
  ) : (
    <nav
      className="menuBar"
      style={{
        background: "rgb(255, 255, 255)",
        borderBottom: "1px double lightgray",
        borderTopColor: "lightgray",
        borderRightColor: "lightgray",
        borderLeftColor: "lightgray",
        // paddingLeft: mobileView ? "0" : "40px",
        // paddingRight: "40px",
        position: "sticky",
        top: "0",
        width: "100%",
        zIndex: "1",
        overflow: "hidden",
        height: "70px",
        padding: mobileView ? " 0 20px 0 0" : "0 20px",
      }}
    >
      {stepper
        ? stepperView({ current: props.pathname === "/signup" ? 1 : 2 })
        : menu()}
    </nav>
  );
};

export default Navbar;
