import { Button, Col, Drawer, Modal, Row, Space, Upload } from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import React, { useEffect, useState } from "react";
import UploadEssay from "../UploadEssay";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import { UploadOutlined } from "@ant-design/icons";
import mixpanel from "mixpanel-browser";
import { useAssitanceChatBotContext } from "context/ChatbotContext";

const TextEditor = ({
  onChange,
  value,
  error,
  details,
  scholarshipId,
  ...moreProps
}) => {
  const {
    localData,
    filledData,
    setFilledData,
    setShowChat,
    setChatBtnType,
    setLocalData,
  } = useAssitanceChatBotContext();
  const [open, setOpen] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Scholarship Detail");
  const { field, title, description, optional } = details;

  // const reqData = JSON.parse(localStorage.getItem("reqData"));
  const existData = filledData ? filledData[field?.key] : "";
  // console.log("🚀 ~ existData:", existData)
  // const reqData = JSON.parse(localStorage.getItem("reqData"));
  // const existData = reqData ? reqData[field?.key] : "";

  const completionStatus =
    field.key === "essay"
      ? value?.text
        ? "Edit Essay"
        : "Effortless Essay Completion"
      : value?.text
      ? "Edit Answer"
      : "Effortless Question Completion";
  const uploadStatus =
    field.key === "essay"
      ? value?.url
        ? "Edit uploaded Essay"
        : "Upload an Essay"
      : value?.url
      ? "Edit uploaded Answer"
      : "Upload Answer";

  const onFileUploadClick = (e) => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Upload file clicked", JSON.stringify(label));
    mixpanel.track("Upload file clicked");

    const { file } = e;

    if (file.status === "done") {
      // const getReqData = localStorage.getItem("reqData");
      let reqData = filledData || {};

      // reqData[field?.field?.key] = { url: file?.response?.data };
      console.log("A", reqData, reqData[field?.field?.key]);

      delete reqData[field?.field?.key];

      // console.log(reqData)
    }
    onChange(e);
  };

  const showDrawer = () => {
    let label = {
      screenName: "Scholarship Detail",
      scholarshipId,
    };
    gaEventTracker("Write Lenny Easy clicked", JSON.stringify(label));
    mixpanel.track("Write Lenny Easy clicked");

    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const uploadProps = !!value?.text
    ? {
        itemRender: () => null,
      }
    : {};

  useEffect(() => {}, [localData]);

  useEffect(() => {
    if (existData) {
      // console.log("🚀 ~ useEffect ~ existData:", existData, {
      //   ...filledData,
      //   [field?.key]: existData,
      // });
      onChange({
        ...filledData,
        [field?.key]: existData,
      });
    }
  }, []);
  // }, [existData]);

  return (
    <div>
      <div>
        {/* <div className="container"> */}

        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <div
            style={{
              flex: "1 1 -1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Upload
              action={API_UPLOAD_URL}
              accept=".doc,.docx,.pdf"
              maxCount={1}
              onChange={onFileUploadClick}
              onRemove={() => onChange(null)}
              showUploadList={{
                showRemoveIcon: false,
              }}
              {...moreProps}
              {...uploadProps}
            >
              <Button
                style={{
                  borderColor: "black",
                  background: "transparent",
                  padding: "12px 20px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  height: "60px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src="/applicantImg/upload.png"
                    style={{ width: "36px", marginRight: "8px" }}
                  />
                  <p
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: 0,
                    }}
                  >
                    {uploadStatus}
                  </p>
                </div>
              </Button>
            </Upload>
          </div>

          <span
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            or
          </span>

          <div
            style={{
              flex: "1 1 -1%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              onClick={(e) => {
                setChatBtnType(e.target.dataset.fieldKey);
                setShowChat(true);
              }}
              data-field-key={field?.key}
              style={{
                border: "none",
                background: "rgb(141, 150, 255)",
                padding: "12px 20px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                height: "60px",
                marginRight: "8px",
                marginTop: window.innerWidth < 900 && "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src="/applicantImg/robo_head.png"
                  alt="lennyLogo"
                  style={{ width: "36px", marginRight: "8px" }}
                />
                <p
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0",
                  }}
                >
                  Take help of Assistant
                </p>
              </div>
            </Button>

            <Button
              onClick={showDrawer}
              style={{
                width: "100%",
                color: "#727EC4",
                margin: "0",
                lineHeight: "1px",
                fontSize: "16px",
                fontWeight: "500",
                cursor: "pointer",
                border: "none",
                display: existData ? "block" : "none",
              }}
            >
              <u>View Essay</u>
            </Button>
          </div>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        visible={open}
        onCancel={onClose}
        footer={null}
        width="80%"
        bodyStyle={{
          height: "80vh",
          overflowY: "auto",
          padding: "0px",
          borderRadius: "24px",
        }}
        className="custom-modal"
      >
        <UploadEssay
          details={details}
          onSave={(val) => {
            // const getReqData = localStorage.getItem("reqData");
            // let reqData = JSON.parse(getReqData) || {};
            let reqData = filledData || {};

            // reqData = {
            //   ...reqData,
            //   [field?.key]: { text: val },
            // };
            // console.log(reqData);
            // localStorage.setItem("reqData", JSON.stringify(reqData));
            reqData[field?.field?.key] = { text: val };

            setFilledData(() => {
              return reqData;
            });

            onChange({ essay: val });
            onClose();
          }}
          onGoBack={onClose}
          existingValue={value?.text || (!value?.url ? value : "")}
          drawerOpen={open}
        />
      </Modal>
    </div>
  );
};

export default TextEditor;
