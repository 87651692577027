import { API_BASE_URL } from "configs/AppConfig.js";
import i18nIsoCountries from "i18n-iso-countries";
import { createContext, useContext, useState, useEffect, useRef } from "react";

const AssistanceChatBotContext = createContext();

const AssistanceChatBotProvider = ({ children }) => {
  const [chatType, setChatType] = useState("");
  const topRef = useRef(null);

  const [messages, setMessages] = useState([
    // {
    //     sender: "receiver",
    //     text: "Hi, I'm Lenny, your friendly AI assistant here to help.",
    // },
    // {
    //     sender: "user",
    //     text: "Welcome",
    // }
  ]);
  const [render, setRender] = useState(true);
  const [startChat, setStartChat] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const [hideIcons, setHideIcons] = useState(false);
  const [maximizeView, setMaximizeView] = useState(false);

  const handleScrollToTop = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setChatTypeFn = (type) => {
    setChatType(type);
  };

  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [citizenship, setCitizenship] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [schoolLevel, setSchoolLevel] = useState([]);
  const [militaryAffiliation, setMilitaryAffiliation] = useState([]);
  const [gpa, setGpa] = useState([]);
  const [highSchoolName, setHighSchoolName] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [fieldOfStudy, setFieldOfStudy] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  const [careerGoal, setCareerGoal] = useState([]);
  const [citizenshipId, setCitizenshipId] = useState();
  const [ethnicityId, setEthnicityId] = useState();
  const [countryId, setCountryId] = useState();
  const [highSchoolNameId, setHighSchoolNameId] = useState();
  const [militaryAffiliationId, setMilitaryAffiliationId] = useState();
  const [gpaId, setGpaId] = useState();
  const [degreeTypeId, setDegreeTypeId] = useState();
  const [careerGoalId, setCareerGoalId] = useState();
  const [highSchoolGradDateId, setHighSchoolGradDateId] = useState();
  const [enrolledInCollegeId, setEnrolledInCollegeId] = useState();
  const [collegeEnrolledDateId, setCollegeEnrolledDateId] = useState();
  const [collegeNameId, setCollegeNameId] = useState();
  const [genderId, setGenderId] = useState();
  const [fieldOfStudyId, setFieldOfStudyId] = useState();
  const [currentSchoolLevelId, setCurrentSchoolLevelId] = useState();
  const [zipCodeId, setZipCodeId] = useState();
  const [collegeGraduationDateId, setCollegeGraduationDateId] = useState();
  const [interestedInOnlineId, setInterestedInOnlineId] = useState();
  const [interestedCollegesId, setInterestedCollegesId] = useState();

  const [numberForCountryName, setNumberForCountryName] = useState();
  i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  // Get the stored country code
  const shortCountryName = localStorage.getItem("shortCountry") ?? "us";
  const [country, setCountry] = useState([]);
  const countryName = i18nIsoCountries.getName(shortCountryName, "en") || "USA";

  const [hideNavAlert, setHideNavAlert] = useState(false);
  const [localData, setLocalData] = useState(false);
  const [assMessages, setAssMessages] = useState([]);
  const [qusCounter, setQusCounter] = useState(0);
  const [forceStopRender, setForceStopREnder] = useState(true);
  const [chatBtn, setChatBtn] = useState(true);
  const [reqAssQusArr, setReqQusArr] = useState([]);
  const [filledData, setFilledData] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [chatBtnType, setChatBtnType] = useState("");
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [reqAssMaxView, setReqMaxView] = useState(false);
  const [mobReqAssMaxView, setMobReqMaxView] = useState(false);
  const [hint, setHint] = useState("");
  const [first, setFirst] = useState(true);
  const [applyScholarshipId, setApplyScholarshipId] = useState("");
  const [chatAssBtn, setChatAssBtn] = useState(false);

  const [runTour, setRunTour] = useState(false);
  const [steps, setSteps] = useState([]);

  const startTour = (tourSteps) => {
    setSteps(tourSteps);
    setRunTour(true);
  };

  const endTour = () => {
    setRunTour(false);
    setSteps([]);
  };

  useEffect(() => {
    fetch(`${API_BASE_URL}api/v1/register-applicants/form-fields`)
      .then((response) => response.json())
      .then((res) => {
        const res_ = res.data;
        setData(res_);
        setLoading(false);
        setCitizenship(
          res_?.filter((e) => e.key === "citizenship")[0]?.options
        );
        setEthnicity(res_?.filter((e) => e.key === "ethnicity")[0]?.options);
        setSchoolLevel(
          res_?.filter((e) => e.key === "schoolLevel")[0]?.options
        );
        setMilitaryAffiliation(
          res_?.filter((e) => e.key === "militaryAffiliation")[0]?.options
        );
        setGpa(res_?.filter((e) => e.key === "gpa")[0]?.options);
        setHighSchoolName(
          res_?.filter((e) => e.key === "highSchoolName")[0]?.options
        );

        // console.log("country", country);
        setCollegeName(
          res_?.filter((e) => e.key === "collegeName")[0]?.options
        );
        setFieldOfStudy(
          res_?.filter((e) => e.key === "fieldOfStudy")[0]?.options
        );
        setDegreeType(res_?.filter((e) => e.key === "degreeType")[0]?.options);
        setCareerGoal(res_?.filter((e) => e.key === "careerGoal")[0]?.options);
        setCitizenshipId(res_?.filter((e) => e.key === "citizenship"));
        setEthnicityId(res_?.filter((e) => e.key === "ethnicity"));
        setCountryId(res_?.filter((e) => e.key === "country"));
        setCountry(res_?.filter((e) => e.key === "country")[0]?.options || {});

        setHighSchoolNameId(res_?.filter((e) => e.key === "highSchoolName"));
        setMilitaryAffiliationId(
          res_?.filter((e) => e.key === "militaryAffiliation")
        );
        setGpaId(res_?.filter((e) => e.key === "gpa"));
        setDegreeTypeId(res_?.filter((e) => e.key === "degreeType"));
        setCareerGoalId(res_?.filter((e) => e.key === "careerGoal"));
        setHighSchoolGradDateId(
          res_?.filter((e) => e.key === "highSchoolGraduationDate")
        );
        setEnrolledInCollegeId(
          res_?.filter((e) => e.key === "enrolledInCollege")
        );
        setCollegeEnrolledDateId(
          res_?.filter((e) => e.key === "enrollmentDate")
        );
        setCollegeNameId(res_?.filter((e) => e.key === "collegeName"));
        setGenderId(res_?.filter((e) => e.key === "gender"));
        setFieldOfStudyId(res_?.filter((e) => e.key === "fieldOfStudy"));
        setCurrentSchoolLevelId(res_?.filter((e) => e.key === "schoolLevel"));
        setZipCodeId(res_?.filter((e) => e.key === "zipCode"));
        setCollegeGraduationDateId(
          res_?.filter((e) => e.key === "collegeGraduationDate")
        );
        setInterestedInOnlineId(
          res_?.filter((e) => e.key === "isInterestedInOnlineStudy")
        );
        setInterestedCollegesId(
          res_?.filter((e) => e.key === "interestedColleges")
        );
        const country_list =
          res_?.filter((e) => e.key === "country")[0]?.options || {};
        setNumberForCountryName(
          Object.keys(country_list).find(
            (number) => country_list[number] === countryName
          ) || "1"
        );
      })
      .catch((error) => {
        console.error("Fetching error:", error);
        // setError(error);
        setLoading(false);
      })
      .finally((_) => setLoading(false));
  }, []);

  return (
    <AssistanceChatBotContext.Provider
      value={{
        first,
        setFirst,
        applyScholarshipId,
        setApplyScholarshipId,
        chatAssBtn,
        setChatAssBtn,
        hint,
        setHint,
        reqAssMaxView,
        setReqMaxView,
        mobReqAssMaxView,
        setMobReqMaxView,
        showChat,
        setShowChat,
        chatBtnType,
        setChatBtnType,
        reqAssQusArr,
        setReqQusArr,
        filledData,
        setFilledData,
        forceStopRender,
        setForceStopREnder,
        chatBtn,
        setChatBtn,
        localData,
        setLocalData,
        assMessages,
        setAssMessages,
        qusCounter,
        setQusCounter,
        runTour,
        setRunTour,
        steps,
        startTour,
        endTour,
        chatType,
        setChatTypeFn,
        messages,
        setMessages,
        render,
        setRender,
        startChat,
        setStartChat,
        hideIcons,
        setHideIcons,
        enableChat,
        setEnableChat,
        maximizeView,
        setMaximizeView,
        loading,
        setLoading,
        data,
        setData,
        citizenship,
        setCitizenship,
        ethnicity,
        setEthnicity,
        schoolLevel,
        setSchoolLevel,
        militaryAffiliation,
        setMilitaryAffiliation,
        gpa,
        setGpa,
        highSchoolName,
        setHighSchoolName,
        collegeName,
        setCollegeName,
        fieldOfStudy,
        setFieldOfStudy,
        degreeType,
        setDegreeType,
        careerGoal,
        setCareerGoal,
        citizenshipId,
        setCitizenshipId,
        ethnicityId,
        setEthnicityId,
        countryId,
        setCountryId,
        highSchoolNameId,
        setHighSchoolNameId,
        militaryAffiliationId,
        setMilitaryAffiliationId,
        gpaId,
        setGpaId,
        degreeTypeId,
        setDegreeTypeId,
        careerGoalId,
        setCareerGoalId,
        highSchoolGradDateId,
        setHighSchoolGradDateId,
        enrolledInCollegeId,
        setEnrolledInCollegeId,
        collegeEnrolledDateId,
        setCollegeEnrolledDateId,
        collegeNameId,
        setCollegeNameId,
        genderId,
        setGenderId,
        fieldOfStudyId,
        setFieldOfStudyId,
        currentSchoolLevelId,
        setCurrentSchoolLevelId,
        zipCodeId,
        setZipCodeId,
        collegeGraduationDateId,
        setCollegeGraduationDateId,
        interestedInOnlineId,
        setInterestedInOnlineId,
        interestedCollegesId,
        setInterestedCollegesId,
        numberForCountryName,
        setNumberForCountryName,
        country,
        setCountry,
        hideNavAlert,
        setHideNavAlert,
        handleScrollToTop,
        topRef,
      }}
    >
      {children}
    </AssistanceChatBotContext.Provider>
  );
};

const useAssitanceChatBotContext = () => {
  return useContext(AssistanceChatBotContext);
};

export { AssistanceChatBotProvider, useAssitanceChatBotContext };
