import { Row, Col, Form, Button, Select, Progress, Tabs, Space } from "antd";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import moment from "moment";
import { SelectDatepicker } from "react-select-datepicker";
import React, { useState, useCallback, useEffect } from "react";
import registerStyle from "../registerStyle.js";
import "../register.scss";
import Avatar from "react-avatar";
import EducationDetail from "./EducationDetail.js";
import BasicDetail from "./BasicDetail.js";
import ContactDetail from "./ContactDetail.js";
import "./profile.css";
import useAnalyticsEventTracker from "useAnalyticsEventTracker.js";
import mixpanel from "mixpanel-browser";

const Profile = ({ history: his, props }) => {
  const items = [
    { label: "Education", key: "education", children: "Content 1" },
    { label: "Basic", key: "basic", children: "Content 2" },
    { label: "Contact", key: "contact", children: "Content 3" },
  ];
  const mobileView = window.innerWidth < 1200;

  const userInfoString = localStorage.getItem("userInfo");
  const storedValue = localStorage.getItem("isActiveSubscription");

  const gaEventTracker = useAnalyticsEventTracker("Profile");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  const isActiveSubscription = storedValue === "true";
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const fullName = userInfo.firstName?.toUpperCase() + " " + userInfo.lastName;
  function _calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const history = useHistory();

  const [{ data: stats }] = useAxios(
    { url: `scholarships/stats` },
    { useCache: false }
  );

  useEffect(() => {
    if (history.location.pathname === "/auth/profile" && props.setSideBars) {
      props.setSideBars(true);
    }
  }, [history]);

  useEffect(() => {
    return () => {
      props.setSideBars(false);
    };
  }, []);

  /*on tab change updating url*/
  const onTabClickFunc = (clickedTab) => {};

  return (
    <div style={{ background: "#ffffff" }}>
      {/*
          history.location.pathname === "/auth/profile" && <a
            onClick={() => {
              props.setSideBars && props.setSideBars(false);
              history.goBack();
            }}
            style={{ color: "#1D252D", marginLeft: "50px" }}
          >
            <img
              src="/applicantImg/back.png"
              style={{ height: "18px", marginRight: "4px" }}
            />
          </a>
        */}
      <Row
        style={{
          padding: "25px",
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Space size={"middle"}>
          <Col style={{ width: mobileView ? "40%" : "20%" }}>
            {false && (
              <img
                src={"/applicantImg/profile.jpg"}
                style={{
                  width: mobileView ? "60px" : "",
                  margin: mobileView ? "40px 0 40px 10px" : "40px",
                  borderRadius: "80px",
                }}
              />
            )}
            <Avatar
              round={true}
              size={60}
              style={{
                // width: mobileView ? "150px" : "160px",
                height: "auto",
                width: "auto",
                // margin: mobileView ? "40px 0 40px 10px" : "20px 0px 0px 40px",
                borderRadius: "80px",
              }}
              name={fullName}
            />
          </Col>
          <Col>
            <h1
              style={{
                fontSize: mobileView ? "14px" : "16px",
                fontWeight: "600",
                margin: "unset",
              }}
            >
              {userInfo.firstName + " " + userInfo.lastName}
            </h1>
            <span style={{ fontSize: mobileView ? "12px" : "14px" }}>
              Age: {_calculateAge(new Date(userInfo.dob))}
            </span>
            <div
              style={{
                fontSize: mobileView ? "13px" : "14px",
                // marginTop: "20px",
                // marginBottom: "5px",
              }}
            >
              Eligible for {stats?.yourRecommendation || 0} scholarships
            </div>

            {/* <Progress width={"300px"} percent={90} /> */}
          </Col>
        </Space>
        <div>
          <Button
            style={{
              // position: "absolute",
              width: "245px",
              height: "40px",
              flexShrink: "0",
              // right: "10px",
              // top: "86px",
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "16px",
              borderRadius: "8px",
              border: "1px solid #8D96FF",
              background: "#8D96FF",
              cursor: "pointer",
              marginTop: "20px",
            }}
            onClick={() => {
              let label = {
                buttonTitle: isActiveSubscription
                  ? "View Subscription Plan"
                  : "Buy Subscription Plan",
              };
              gaEventTracker(
                "Subscription Button clicked ",
                JSON.stringify(label)
              );
              mixpanel.track("Subscription Button clicked");

              const destination = isActiveSubscription
                ? "/auth/dashboard/plan"
                : "/auth/dashboard/subscription-plan";
              history.push(destination);
              //history.push("/auth/dashboard/plan");
            }}
          >
            {isActiveSubscription
              ? "View Subscription Plan"
              : "Buy Subscription Plan"}
          </Button>
        </div>
      </Row>
      <div
        style={{
          borderBottom: "double",
          borderSize: "1px",
          borderWidth: "1px",
          borderBottomColor: " rgba(114, 132, 154, 0.2)",
        }}
      />
      <Tabs
        items={items}
        // centered
        tabBarStyle={{ paddingLeft: "25px" }}
        // activeKey={}
        onChange={(key) => onTabClickFunc(key)}
        className="tabstyle"
      >
        <Tabs.TabPane tab="Education" key="education">
          <div>
            <EducationDetail />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Basic" key="basic">
          <BasicDetail />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Contact" key="contact">
          <ContactDetail />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Profile;
