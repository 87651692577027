const hostname = window.location.hostname;
const hostnameParts = hostname.split(".");
const NODE_ENV = process.env.NODE_ENV;
const origin =
  NODE_ENV === "development"
    ? "https://api.lenny.ai/"
    : window.location.origin + "/";
// const origin =
//   NODE_ENV === "development"
//     ? "https://api-scholarship.lenlen.ai/"
//     : window.location.origin + "/";

// export const subdomain =
//   NODE_ENV === "development"
//     ? "qenjV7k"
//     : hostnameParts.length === 3
//     ? hostnameParts[0]
//     : null;
export const subdomain = null;
export const isSubdomain = !!subdomain;

const dev = {
  APP_NAME: "Application",

  API_ENDPOINT_URL: "https://api.lenny.ai/",
  API_CHAT_URL: "https://chat.lenny.ai/",
  // API_ENDPOINT_URL: "https://api-scholarship.lenlen.ai/",
  // API_CHAT_URL: "https://chat-api.lenlen.ai/",
  // API_ENDPOINT_URL: "http://localhost:3000/",
  FRONTEND_DOMAIN: "localhost",
};

const prod = {
  APP_NAME: "Application",
  // API_ENDPOINT_URL: "https://api-scholarship.lenlen.ai/",
  // API_CHAT_URL: "https://chat-api.lenlen.ai/",
  API_CHAT_URL: "https://chat.lenny.ai/",
  API_ENDPOINT_URL: "https://api.lenny.ai/",
  FRONTEND_DOMAIN: "localhost",
};

export const HOTJAR_ID = "5031092";
export const HOTJAR_SV = "6";

const getEnv = () => {
  switch (NODE_ENV) {
    case "production":
      return prod;
    default:
      return dev;
  }
};

export const env = getEnv();
