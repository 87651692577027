import { Button, Checkbox, Col, message, Row, Typography } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import DOMPurify from "dompurify";
import { useState } from "react";
import fetch_ from "../../../../interceptors/FetchInterceptor";
import useAnalyticsEventTracker from "../../../../useAnalyticsEventTracker";
import Utils from "utils/common";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mixpanel from "mixpanel-browser";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import { FREE_SCHOLARSHIP_COUNT } from "constants/common.constant";

export const FilterList = ({
  data,
  history,
  onCheckboxChange,
  element,
  ...props
}) => {
  const { setFilledData, setAssMessages, setShowChat, setForceStopREnder } =
    useAssitanceChatBotContext();
  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 961;
  const mView = window.innerWidth < 600;
  const [tempData, setTempData] = useState({ ...data });

  // const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const tabValue = searchParams.get("tab");
  // console.log("🚀 ~ tabValue:", tabValue);

  const [isOpen, setIsOpen] = useState(false);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (tabView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };
  const storedValue = localStorage.getItem("isActiveSubscription");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  const isActiveSubscription = storedValue === "true";

  const title = props.type
    ? props.type === "recommendation"
      ? "Your Recommendation "
      : props.type === "saved"
      ? "Wishlist / Saved "
      : "Apply With CV Data Only"
    : "Easy Apply Scholarships ";

  const { Text } = Typography;

  const gaEventTracker = useAnalyticsEventTracker(title);

  const onChange = (e) => {
    onCheckboxChange(data.id, e.target.checked);
  };

  const likeClick = () => {
    let label = {
      screenName: title,
      scholarshipId: data.scholarshipId || data.id || data.dataValues.id,
      wishlist: tempData?.wishlist?.length > 0,
    };
    gaEventTracker("Wishlist clicked", JSON.stringify(label));
    mixpanel.track("Wishlist clicked");
    if (props.type === "saved") {
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      fetch_({
        url: `scholarships/remove-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {
          props.onSavedLike(scholarshipId);
        })
        .catch((error) => {});
      return;
    }
    if (false && props.type === "recommendation") {
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      fetch_({
        url: `scholarships/add-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {
          props.onSavedLike(scholarshipId);
        })
        .catch((error) => {});
      return;
    }
    if (
      props.type !== "recommendation" &&
      props.type !== "saved" &&
      tempData?.wishlist?.length > 0
    ) {
      setTempData({ ...data, wishlist: [] });
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      fetch_({
        url: `scholarships/remove-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
      return;
    } else if (
      props.type !== "recommendation" &&
      props.type !== "saved" &&
      tempData?.wishlist?.length === 0
    ) {
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      setTempData({ ...data, wishlist: [{ scholarshipId: scholarshipId }] });
      fetch_({
        url: `scholarships/add-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
      return;
    }
    if (tempData?.wishlist?.length > 0) {
      setTempData({ ...data, wishlist: [] });
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      fetch_({
        url: `scholarships/remove-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
    } else {
      let scholarshipId = data.scholarshipId || data.id || data.dataValues.id;
      setTempData({ ...data, wishlist: [{ scholarshipId: scholarshipId }] });
      fetch_({
        url: `scholarships/add-wishlist`,
        method: "PUT",
        data: { scholarshipId: scholarshipId },
      })
        .then(({ data }) => {})
        .catch((error) => {});
    }
  };

  const easyClick = () => {
    return;
    let label = {
      screenName: title,
      scholarshipId: data.scholarshipId || data.id || data.dataValues.id,
    };
    gaEventTracker("Easy Apply clicked", JSON.stringify(label));
    let selectedId = [data.id];
    props.setEasyApplyClicked(selectedId);
    props.setModalVisible(true);
  };

  const viewDetail = () => {
    let label = {
      screenName: title,
      scholarshipId: data.scholarshipId || data.id || data.dataValues.id,
    };
    gaEventTracker("Scholarship clicked", JSON.stringify(label));
    mixpanel.track("Scholarship clicked");
    history.push({
      pathname: "/auth/scholarshipDetail",
      search: data.scholarshipId || data.id || data.dataValues.id,
      params: props.type,
    });
    props.setSideBars(true);
  };

  const amountView = (width = "11%") => {
    // console.log(data);
    const totalAmount =
      (data?.amounts && data.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.scholarship?.amounts &&
        data?.scholarship?.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      (data?.dataValues?.amounts &&
        data.dataValues.amounts.reduce((acc, curr) => acc + curr, 0)) ||
      data?.scholarship?.awards ||
      data?.awards ||
      data?.dataValues?.awards;
    return (
      <Col style={{ width: width }}>
        <h2 style={{ margin: "0" }}>{Utils.formatAsUSD(totalAmount)}</h2>
      </Col>
    );
  };
  if (data?.isLiked === false) {
    return <div />;
  }

  let imgSrc =
    props.type === "saved" ? (
      <HeartFilled style={{ color: "#8D96FF" }} />
    ) : false && props.type === "recommendation" ? (
      <HeartOutlined />
    ) : tempData?.wishlist?.length > 0 ? (
      <HeartFilled style={{ color: "#8D96FF" }} />
    ) : (
      <HeartOutlined />
    );

  function extractTextFromHTML(html) {
    // Create a temporary element
    const tempElement = document.createElement("div");
    // Set the HTML content of the temporary element
    tempElement.innerHTML = html;
    // Return the text content of the temporary element
    return tempElement.textContent || tempElement.innerText || "";
  }

  const WebView = () => {
    return (
      <>
        <Col style={{ width: mobileView ? "68%" : "40%" }}>
          <p>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "19px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#1D252D",
              }}
            >
              {data?.title ||
                data?.scholarship?.title ||
                data?.dataValues?.title ||
                "No Name"}
            </span>
          </p>
          <p
            style={{
              color: "black",
              fontWeight: "400",
            }}
          >
            {extractTextFromHTML(
              DOMPurify.sanitize(
                data?.description ||
                  data?.scholarship?.description ||
                  data?.dataValues?.description ||
                  "Essay - - - - - -"
              )
            )}
          </p>
          {/* <p
            style={{
              color: "#1D252D",
              display: "block",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              overflow: "hidden",
              maxHeight: "3.6em",
              lineHeight: "1.8em",
              marginTop: "0",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                data?.description ||
                  data?.scholarship?.description ||
                  data?.dataValues?.description ||
                  "Essay - - - - - -"
              ),
            }}
          /> */}
        </Col>
        {mobileView && amountView()}
        <Col
          style={{
            width: mobileView ? "100%" : "25%",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0em",
            padding: mobileView ? "" : "0 5%",
            display: mobileView && "inline-flex",
          }}
        >
          <div
            style={{
              margin: "0",
              paddingRight: mobileView && "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                src="/applicantImg/watch.png"
                style={{ height: "12px", marginRight: "4px" }}
              />
              {!mobileView && <Text>Deadline</Text>}
            </div>
            <div>
              {data?.recurrenceConfig?.deadline ||
                data?.scholarship?.recurrenceConfig?.deadline ||
                data?.dataValues?.recurrenceConfig?.deadline}
            </div>
          </div>
          <br />
          <span>Sponsored by </span>
          <div>
            {data?.user?.firstName ||
              data?.scholarship?.user?.firstName ||
              data?.dataValues?.user?.firstName}
          </div>
        </Col>

        {mobileView
          ? props.type !== "recommendation" &&
            props.type !== "saved" && <Col style={{ width: "40px" }} />
          : amountView()}
        <Col
          style={{
            width: !mobileView && "14%",
            paddingBottom: mobileView && "20px",
            paddingRight: mobileView && "10px",
            marginTop: mobileView ? "5px" : "",
          }}
        >
          {/* {props.type === "saved" || props.type === "recommendation" ||
          props.type === "myscholarship/draft" || props.type === "myscholarship/applied" ? ( */}
          <Button
            type="primary"
            onClick={() => {
              // console.log(isActiveSubscription, props.type, tabValue);
              if (
                isActiveSubscription ||
                props.type === "myscholarship/draft" ||
                props.type === "myscholarship/applied"
              ) {
                setFilledData(() => {
                  return {};
                });
                setAssMessages(() => {
                  return [];
                });
                setShowChat(false);
                setForceStopREnder(true);

                viewDetail();
              } else if (tabValue === "applied") {
                setFilledData(() => {
                  return {};
                });
                setAssMessages(() => {
                  return [];
                });
                setShowChat(false);

                setForceStopREnder(true);

                viewDetail();
              } else {
                if (userInfo?.scholarshipCount < FREE_SCHOLARSHIP_COUNT) {
                  setFilledData(() => {
                    return {};
                  });
                  setAssMessages(() => {
                    return [];
                  });
                  setShowChat(false);
                  setForceStopREnder(true);

                  viewDetail();
                } else {
                  message.error(
                    `You have exceeded the limit of ${FREE_SCHOLARSHIP_COUNT} free scholarship views. Please subscribe to continue accessing more scholarships.`
                  );
                  togglePopup();
                }
              }
            }}
            style={{
              height: "32px",
              width: "123px",
              paddingTop: "5px",
              borderRadius: "8px",
              borderColor: "#8D96FF",
              backgroundColor: "#8D96FF",
              color: "white",
            }}
          >
            <span style={{}}>View Detail</span>
          </Button>
          {/* ) : (
            <Button
              type="default"
              onClick={easyClick}
              style={{
                borderRadius: "6px",
                borderColor: "#F1F6FE",
                height: "32px",
                width: "123px",
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/applicantImg/tick.png"
                style={{ height: "10px", marginRight: "4px" }}
              />
              <span style={{ color: "#1778F2" }}>Easy Apply </span>
            </Button>
          )} */}
        </Col>
        <Col>
          <Button
            type="default"
            style={{
              borderStyle: "solid",
              borderRadius: "5px",
              //borderColor:
              // imgSrc !== "/applicantImg/heart.png" ? "#1778F2" : "#E9E9EA",
            }}
            onClick={likeClick}
          >
            {imgSrc}
          </Button>
        </Col>
      </>
    );
  };

  const MobileView = () => {
    return (
      <div>
        <p>
          <span
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "19px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#1D252D",
            }}
          >
            {data?.title ||
              data?.scholarship?.title ||
              data?.dataValues?.title ||
              "No Name"}
          </span>
        </p>

        <p
          style={{
            color: "black",
            fontWeight: "400",
          }}
        >
          {extractTextFromHTML(
            `${DOMPurify.sanitize(
              data?.description ||
                data?.scholarship?.description ||
                data?.dataValues?.description ||
                "Essay - - - - - -"
            ).slice(0, 50)}...`
          )}
        </p>
        <div
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {amountView("auto")}
          <div
            style={{
              margin: "0 0 0 8px",
              paddingRight: mobileView && "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                src="/applicantImg/watch.png"
                style={{ height: "12px", marginRight: "4px" }}
              />
              {!mobileView && <Text>Deadline</Text>}
            </div>
            <div
              style={{
                marginRight: "8px",
              }}
            >
              {data?.recurrenceConfig?.deadline ||
                data?.scholarship?.recurrenceConfig?.deadline ||
                data?.dataValues?.recurrenceConfig?.deadline}
            </div>
            {/* <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"

          }}>
            <p >Sponsored by </p>
            <div>
              {data?.user?.firstName ||
                data?.scholarship?.user?.firstName ||
                data?.dataValues?.user?.firstName}
            </div>
          </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
            marginTop: "8px",
          }}
        >
          <Button
            type="default"
            style={{
              borderStyle: "solid",
              borderRadius: "5px",
              //borderColor:
              // imgSrc !== "/applicantImg/heart.png" ? "#1778F2" : "#E9E9EA",
              width: "max-content",
              marginRight: "8px",
            }}
            onClick={likeClick}
          >
            {imgSrc}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (
                isActiveSubscription ||
                props.type === "myscholarship/draft" ||
                props.type === "myscholarship/applied"
              ) {
                setFilledData(() => {
                  return {};
                });
                setAssMessages(() => {
                  return [];
                });
                setShowChat(false);
                setForceStopREnder(true);

                viewDetail();
              } else if (tabValue === "applied") {
                setFilledData(() => {
                  return {};
                });
                setShowChat(false);
                setForceStopREnder(true);

                setAssMessages(() => {
                  return [];
                });
                viewDetail();
              } else {
                if (userInfo?.scholarshipCount < FREE_SCHOLARSHIP_COUNT) {
                  setFilledData(() => {
                    return {};
                  });
                  setAssMessages(() => {
                    return [];
                  });
                  setShowChat(false);
                  setForceStopREnder(true);

                  viewDetail();
                } else {
                  message.error(
                    `You have exceeded the limit of ${FREE_SCHOLARSHIP_COUNT} free scholarship views. Please subscribe to continue accessing more scholarships.`
                  );
                  togglePopup();
                }
              }
            }}
            style={{
              height: "32px",
              width: "100%",
              paddingTop: "5px",
              borderRadius: "8px",
              borderColor: "#8D96FF",
              backgroundColor: "#8D96FF",
              color: "white",
            }}
          >
            <span style={{}}>View Detail</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Row
        style={{
          padding: mobileView ? "2% 4% 0" : "1% 2% 0",
          justifyContent: mobileView ? "" : "space-between",
          border: "double",
          borderWidth: "1px",
          background: data.isSelected ? "#F0F1FF" : "#FFFFFF",
          borderColor: "#F0F1FF",
          borderTopRightRadius: element == 0 && "10px",
          borderTopLeftRadius: element == 0 && "10px",
          marginBottom: "10px",
        }}
      >
        {props.type !== "saved" && props.type !== "recommendation" && (
          <Col style={{ width: mobileView ? "40px" : "4%" }}>
            <Checkbox
              style={{}}
              checked={data?.isSelected}
              defaultChecked={data?.isSelected}
              onChange={onChange}
            />
          </Col>
        )}
        {mView ? <MobileView /> : <WebView />}
      </Row>
      {isOpen &&
        (tabView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              {`${userInfo?.firstName}`} get a membership for free 7 days to
              activate automatic application to your 234 scholarship matches.
              Lets us do the hard work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={togglePopup}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={togglePopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
